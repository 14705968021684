.input_area{
    width: 100%;
    height: fit-content;
    position: relative;
    margin: 1rem 0;

    &[data-label]:focus-within::before{
        color: var(--colorPrimary)
    }
    &[data-error] input{
        border-color: var(--colorError);
    }

    &[data-label]::before{
        position: absolute;
        left: 0.2rem;
        top: -0.7rem;
        content: attr(data-label);
        font-size: 0.85rem;
        line-height: 1;
        border-radius: 5px 5px 0 0;
        color: var(--colorText);
        height: fit-content;
        display: block;
        opacity: 0.6;
    }
    &[data-error]::after{
        position: absolute;
        right: 0;
        top: calc(100%);
        content: attr(data-error);
        font-size: 0.85rem;
        line-height: 1;
        padding: 0;
        border-radius: 5px 5px 0 0;
        color: var(--colorError);
        width: fit-content;
        display: block;
        margin-left: auto;
    }

    & input{
        width: 100%;
        font-size: 1.1rem;
        border-radius: 10px;
        padding: 0.5rem;
        border: 1px solid #0000001f;
        color: var(--colorText);
        padding-right: 2.3rem;
        padding-left: 1rem;
        outline: none;

        &:focus{
            border: 1px solid #00000044;
        }

        &::placeholder{
            opacity: 0.4;
            font-size: 1rem;
            font-style: italic;
        }
    }

    & .input_media{
        position: absolute;
        right: 0;
        top: 0;
        width: 2.3rem;
        height: 100%;
        color: #00000021;
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &[data-error] .input_media{
        // background-color: var(--colorError);
        // color: var(--colorTextAlt);
    }
}

.input_wrapper{
    margin: 0 0 2rem 0;
}