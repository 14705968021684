.shadow_dark{
    box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.10);
}
.shadow_light{
    box-shadow: 0 0 2rem 1rem rgba(255, 255, 255, 0.10);
}
.shadow_primary{
    box-shadow: 0 0 2rem 1rem var(--colorPrimary);
}
.hover_scale:hover{
    transform: scale(1.05);
    transition: transform var(--animationSpeed) ease;
}
.hover_shadow:hover{
    @extend .shadow_dark
}
.cursor_pointer{
    cursor: pointer;
}
.display_none{
    display: none;
}
.zoom_in{
    animation: zoomIn var(--animationSpeed);

    @keyframes zoomIn {
        0% { transform: scale(0); }
        100% { transform: scale(1); }
    }
}
.float_right {
    float: right;
}

.slide_out_left{
    animation: slideOutLeft var(--animationSpeed);
    animation-fill-mode: forwards;

    @keyframes slideOutLeft {
        0% { transform: translateX(0); }
        100% { transform: translateX(-100vw); }
    }
}

.slide_in_from_left{
    animation: slideInFromLeft var(--animationSpeed);

    @keyframes slideInFromLeft {
        0% { transform: translateX(-100vw); }
        100% { transform: translateX(0); }
    }
}

.slide_in_from_right{
    animation: slideInFromRight var(--animationSpeed);

    @keyframes slideInFromRight {
        0% { transform: translateX(100vw); }
        100% { transform: translateX(0); }
    }
}

.slide_out_right{
    animation: slideOutRight var(--animationSpeed);
    animation-fill-mode: forwards;

    @keyframes slideOutRight {
        0% { transform: translateX(0); }
        100% { transform: translateX(100vw); }
    }
}

.slide_out_down{
    animation: slideOutDown var(--animationSpeed);
    animation-fill-mode: forwards;

    @keyframes slideOutDown {
        0% { transform: translateY(0); }
        50% { opacity: 1; }
        100% { transform: translateY(100vh); opacity: 0; }
    }
}

.slide_in_from_top{
    animation: slideInFromTop var(--animationSpeed);

    @keyframes slideInFromTop {
        0% { transform: translateY(-100vw); }
        100% { transform: translateY(0); }
    }
}

.slide_in_from_bottom{
    animation: slideInFromBottom var(--animationSpeed);

    @keyframes slideInFromBottom {
        0% { transform: translateY(100vw); width: 0%; }
        50% { width: 20%; }
        100% { transform: translateY(0); width: 100%; }
    }
}

.spin_animation{
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
}