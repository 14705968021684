@import '/src/assets/styles/Responsive.scss';

.hero_container{
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        z-index: 1;
    }

    & > .hero_container_overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(rgb(0 0 0 / 59%) 50%, var(--colorPrimary));
        backdrop-filter: saturate(0.8);

        z-index: 0;
    }
    
    .hero_main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        user-select: none;

        .hero_pre_title{
            color: var(--colorPageBackground);
            font-size: 3rem;
            line-height: 1;
            font-family: var(--accentFontSecondary);
            font-weight: 600;
            @include size_S {    
                font-size: 2rem;
            }
        }
        .hero_title{
            font-family: var(--accentFontPrimary);
            color: var(--colorPageBackground);
            font-size: 12rem;
            margin: 0;
            line-height: 1;
            letter-spacing: 6px;

            @include size_M {    
                font-size: 8rem;
            }
            @include size_S {    
                font-size: 4rem;
            }
        }
        .horizontal_line{
            background-color: var(--colorSecondary);
            width: 60%;
            height: 5px;
        }
        .hero_subtitle{
            margin-top: 1rem;
            color: var(--colorPageBackground);
            font-size: 2.5rem;
            font-weight: 600;
            font-family: var(--accentFontSecondary);

            @include size_S {    
                font-size: 1.5rem;
            }
        }
    }

    .hero_social_icons{
        font-size: 1.5rem;
        position: absolute;
        right: 2rem;
        bottom: 1rem;
        color: var(--colorTextAlt);
        opacity: 0.5;
        display: flex;

        & > *:not(:last-of-type){
            margin-right: 1rem;
        }

        & a{
            color: inherit;
            transition: color var(--animationSpeed) ease;

            &:hover{
                color: var(--colorSecondary);
            }
        }
    }
}