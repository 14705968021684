@import '/src/assets/styles/Responsive.scss';

.contact_us_box{
    display: flex;
    max-width: 1000px;
    margin: auto;

    & .contact_us_info_wrapper{
        padding: 3rem 5rem;
        background: var(--colorPrimaryDark);
        border-radius: 1rem;
        width: 50%;
        flex: 1 1 auto;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .contact_us_info_text{
            font-family: var(--accentFontSecondary);
            font-size: 1.5rem;
            text-align: center;
            margin-top: 2rem;
        }

        img {
            width: 100%;
            max-width: 300px;
            max-height: 100%;
        }

        @include size_S{
            width: 100%;
            padding: 3rem;
        }
    }

    & .contact_us_form_wrapper{
        padding: 3rem;
        background: var(--colorPageBackground);
        color: var(--colorText);
        border-radius: 1rem;
        margin-left: -3rem;
        margin-top: 5rem;
        width: 50%;
        flex: 1 1 auto;
        height: fit-content;

        @include size_S{
            width: 100%;
            margin-left: 0;
            margin-top: 2rem;
            padding: 3rem 2rem;
        }
    }

    .social_media_contact {
        width: 100%;
        border-radius: 1000px;
        padding: 10px 20px;
        background: #0000000a;
        display: flex;
        align-items: center;
        gap: 0.9rem;

        span {
            font-weight: 500;
        }
    }
    
    .social_media_contact_icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #00000040;
        display: flex;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .background_message{
        background: linear-gradient(45deg, var(--colorPrimaryDark), var(--colorPrimaryLight));
        color: white;
    }

    .hover_background_message:hover {
        @extend .background_message;
    }

    .background_facebook {
        background: #1877F2;
        color: white;
    }

    .hover_background_facebook:hover {
        @extend .background_facebook;
    }

    .background_instagram {
        background: #f09433;
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
        color: white;
    }

    .hover_background_instagram:hover {
        @extend .background_instagram;
    }

    .background_flickr{
        background: #FF0084;
        background: linear-gradient(90deg, #0062DC, #FF0084);
        color: white;
    }

    .hover_background_flickr:hover {
        @extend .background_flickr;
    }

    .background_tiktok{
        background: black;
        color: white;
    }

    .hover_background_tiktok:hover {
        @extend .background_tiktok;
    }

    @include size_S{
        flex-direction: column;
    }
}