@import '/src/assets/styles/Responsive.scss';

$menuHeight: 4rem;

.page_menu{
    position: fixed;
    background: #0000002e;
    backdrop-filter: blur(4px);
    width: 100%;
    height: $menuHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    font-size: 2rem;
    color: var(--colorTextAlt);
    z-index: 10;

    font-family: var(--accentFontSecondary);

    padding: 0 8rem;
    @include size_L {    
        padding: 0 5rem;
    }
    @include size_M {    
        padding: 0 3rem;
    }
    @include size_S {    
        padding: 0 2rem;
    }
    @include size_XS {    
        padding: 0 1rem;
    }

    .small_menu_strip{
        height: 100%;
        
        & .menu_logo{
            height: 100%;
            padding: 0.8rem 0;
            opacity: 0.8;

            & img{
                height: 100%;
            }
        }
    }

    @include size_S { 
        &.menu_opened{
            width: 100%;
            height: 100%;
            background-color: var(--colorPrimary);
            flex-direction: column;

            .menu_links{
                display: flex;
                font-size: 4rem;
                width: 100%;
                height: calc(100% - $menuHeight);
                flex-direction: column;
                align-items: center;
                justify-content: center;
                user-select: none;
            }
        }

        & .small_menu_strip{
            width: 100%;
            height: $menuHeight;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .menu_links{
        display: flex;
        align-items: center;

        @include size_S {    
            display: none;
        }

        .menu_item, .menu_item a{
            color: var(--colorTextAlt);
            transition: opacity var(--animationSpeed);
            font-weight: 600;
            border-bottom: 3px solid transparent;

            &:hover {
                border-bottom: 3px solid var(--colorSecondary);
            }
        }
        .menu_item:hover, .menu_item a:hover{
            opacity: 0.7;
        }
        .menu_item:not(:last-of-type){
            margin-right: 2rem;

            @include size_S {
                margin-right: 0;
            }
        }
        .menu_item a.active{
            border-bottom: 2px solid var(--colorSecondary);
        }
    }

    .menu_toggle{
        display: none;
        cursor: pointer;

        @include size_S {    
            display: inline;
        }
    }
}