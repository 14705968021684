@import '/src/assets/styles/Responsive.scss';

.theatre_box{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #111111;
    padding: 5rem 0;
    z-index: 10;

    @include size_L{
        padding: 4rem 0;
    }

    @include size_M{
        padding: 2.5rem 0;
    }

    @include size_S{
        padding: 2rem 0;
    }

    @include size_XS{
        padding: 0.5rem 0;
    }

    & > .close_button{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    & > .next_button, & > .previous_button{
        position: absolute;
        top: calc(50% - 1.3rem) ;
    }

    & > .previous_button{
        left: 1rem;
    }
    & > .next_button{
        right: 1rem;
    }

    & > .quick_access_buttons{
        position: absolute;
        bottom: 1rem;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > .quick_access_button{
            color: white;
            font-size: 1.5rem;
            margin: 0 0.3rem;
            user-select: none;
            cursor: pointer;
        }
    }

    & >  .theatre_view_port {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            max-width: 80%;
            max-height: 100%;

            user-select: none;

            @include size_L{
                max-width: 100%;
            }
        }
    }

    
}