@import '/src/assets/styles/Responsive.scss';

section{
    padding: 2rem 8rem;

    @include size_L {    
        padding: 2rem 5rem;
    }
    @include size_M {    
        padding: 2rem 3rem;
    }
    @include size_S {    
        padding: 2rem 2rem;
    }
    @include size_XS {    
        padding: 2rem 1rem;
    }

    h2{
        margin: 0;
    }

    .section_title{
        font-size: 6rem;
        font-family: var(--accentFontSecondary);
        color: var(--colorPageBackground);
        position: relative;
        line-height: 1;

        @include size_S {    
            font-size: 5rem;
        }
        @include size_XS {    
            font-size: 3.5rem;
        }

        &[data-backdrop]::before{
            content: attr(data-backdrop);
            position: absolute;
            left: -1rem;
            color: #ffffff0a;
            font-size: 7.5rem;
            line-height: 1;
            text-transform: uppercase;
            font-family: var(--accentFontPrimary);
            letter-spacing: 0.5rem;
            bottom: 0;
            max-width: 100%;
            overflow: hidden;

            @include size_S {    
                display: none;
            }
        }
    }

    .section_content{
        margin: 6rem 0;

        @include size_S {
            margin: 3rem 0;
        }
    }
}