@import '/src/assets/styles/Responsive.scss';

.card {
    width: 100%;
    height: 100%;
    border-radius: 0.7rem;
    background-color: rgb(0 0 0 / 7%);
    overflow: hidden;
    display: flex;

    &.card_horizontal{
        flex-direction: row;

        & .card_media{
            width: 50%;
        }

        & .card_content_box {
            width: 50%;
        }

        @include size_S{
            flex-direction: column;

            & .card_media{
                width: 100%;
                padding-top: 56.25%;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                margin: 0
            }
    
            & .card_content_box {
                width: 100%;
            }
        }
    }

    &:not(.card_horizontal){
        flex-direction: column;
        
        .card_media{
            padding-top: 56.25%;
        }
    }

    & .card_media{
        width: 100%;
        background-size: cover;
        background-position: center;
        background-color: var(--colorPrimary);
        position: relative;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            border-radius: inherit;
        }

        & .card_media_overlay{
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #0000001c 0%, rgba(0, 0, 0, 0.14) 100%);
            border-radius: inherit;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    & .card_content_box {
        padding: 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .card_title {
        font-weight: bold;
        font-size: 1.4rem;
        margin: 0;
    }

    & .card_content {
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .card_subtitle {
        opacity: 0.4;
    }

    & .card_actions {
        margin-top: auto;
        width: 100%;
    }
}