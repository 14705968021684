@import '/src/assets/styles/Responsive.scss';

.spinut_footer_additional_info{
    gap: 1rem;

    a {
        opacity: 0.6;
        transition: all var(--animationSpeed) ease;

        &:hover{
            opacity: 0.8;
        }
    }

    @include size_S {
        flex-direction: column;
        gap: 0;
    }
}

.footer_company_name {
    color: var(--colorPrimaryLight);
    font-size: 1.2rem;
    font-weight: bold;

    @include size_S {
        font-size: 1rem;
    }
}