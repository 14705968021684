.about_us_text_box{
    text-align: center;
    font-family: var(--accentFontSecondary);
    font-size: 1.8rem;
    align-items: center;
    display: flex;
}

.about_us_image{
    width: 100%;
    filter: drop-shadow(0px 0.5rem 1rem rgba(1,1,1,0.4));
}