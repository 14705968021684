@mixin app-scheme($appName){
    .application.#{$appName}{
        --colorPrimary: var(--#{$appName}) !important;
        --colorPrimaryDark: var(--#{$appName}Dark) !important;
        --colorPrimaryLight: var(--#{$appName}Light) !important;
        --colorSecondary: var(--#{$appName}Secondary) !important;
        --scrollbarActive: var(--#{$appName}) !important;
        --fontFamilyMain: var(--#{$appName}FontFamily) !important;
        --accentFontPrimary: var(--#{$appName}AccentFontPrimary) !important;
        --accentFontSecondary: var(--#{$appName}AccentFontSecondary) !important;
    }
    .#{$appName}_background{
        background-color: var(--#{$appName}) !important;
        color: white !important;
    }
    .#{$appName}_text{
        color: var(--#{$appName}) !important;
        background-color: white !important;
    }

    .#{$appName}, .#{$appName} + *, .#{$appName} + * *{
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-button:hover,
      *::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-button:hover {
        background-color: var(--#{$appName}) !important;
      }
    }
}

$spinut: #113a62;
$spinutSecondaryColor: #6b031d;

$admin: #2b6690  ;
$adminSecondaryColor: #289ec2 ;

:root{
    --spinut: #{$spinut};
    --spinutDark: #{darken($spinut, 8%)};
    --spinutLight: #{lighten($spinut, 15%)};
    --spinutSecondary: #{$spinutSecondaryColor};
    --spinutFontFamily: 'Baloo Bhaijaan 2', cursive;;
    --spinutAccentFontPrimary: 'Luckiest Guy', cursive;
    --spinutAccentFontSecondary: 'Amatic SC', cursive;

    --admin: #{$admin};
    --adminDark: #{darken($admin, 8%)};
    --adminLight: #{lighten($admin, 15%)};
    --adminSecondary: #{$adminSecondaryColor};
    --adminFontFamily: 'Baloo Bhaijaan 2', cursive;;
    --adminAccentFontPrimary: 'Luckiest Guy', cursive;
    --adminAccentFontSecondary: 'Amatic SC', cursive;
    
    --colorPageBackground: #fff;
    --colorBodyBackground: #eff3f5;
    --colorText: #202020;
    --colorTextLighter: #606060;
    --colorTextAlt: #f0f0f0;
    --colorError: #c10000;
    --colorSuccess: #3eb456;
    --animationSpeed: 0.2s;
    --adminMenuWidth: 330px;
    --adminHeaderHeight: 60px;
}

@include app-scheme('spinut');
@include app-scheme('admin');

// Global styles

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
   
::-webkit-scrollbar-thumb {
    background: rgb(143, 143, 143); 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(177, 177, 177); 
}

* [data-disabled]{
    pointer-events: none;
    opacity: 0.5;
}