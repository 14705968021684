@import '/src/assets/styles/Responsive.scss';

.approved_by_ministry_card {
    max-width: 500px;
    margin: -2rem auto 5rem auto;
    background: var(--colorPrimaryLight);
    color: var(--colorTextAlt);
    font-family: var(--accentFontSecondary);
    font-size: 2rem;
    text-align: center;
    border-radius: 1000px;

    @include size_S {
        font-size: 1.7rem;
    }
    @include size_XS {
        font-size: 1.5rem;
    }

    .card_content_box{
        @include size_S {
            padding: 0.5rem 0
        }
    }
}