@import '/src/assets/styles/Responsive.scss';

#feedbackBox{
    width: 100%;
    max-width: 300px;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 101;

    @include size_S {
        right: unset;
        left: 50%;
        transform: translate(-50%, 0);
    }

    & .feedback {
        width: 100%;
        padding: 1rem;
        background-color: var(--colorText);
        color: var(--colorTextAlt);
        margin-bottom: 0.5rem;
        
        z-index: 100;
        border-radius: 8px;
    
        &.success {
            background-color: var(--colorSuccess);
        }
        &.error {
            background-color: var(--colorError);
        }
    }
}