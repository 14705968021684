.public_footer{
    background-color: #1b1f2a;
    color: var(--colorTextAlt);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem 3rem 2rem;

    .logo_box{
        max-width: 3rem;
        max-width: 3rem;

        & *{
            max-width: 100%;
            max-width: 100%;
        }
    }

    .social_media_box{
        margin-top: 2rem;
    }
}
.additional_info{
    background: #1b1f2a;
    color: white;
    padding: 1rem;
    border-top: 1px solid #ffffff0f;
}
.copyright_row{
    background-color: #171921;
    color: var(--colorTextAlt);
    text-align: center;
    padding: 0.6rem;

    & > span{
        opacity: 0.3;
    }
}